.react-pdf__Page {
    margin-top: 8px;
    margin-bottom: 8px;
    margin-left: 24px;
    margin-right: 24px;
  }
  .react-pdf__Page__textContent {
    border: 1px solid darkgrey;
    box-shadow: 5px 5px 5px 1px #ccc;
    border-radius: 5px;
    /* width: 700 !important; */
  }
  
  .react-pdf__Page__annotations.annotationLayer {
    padding: 20px;
  }
  
  .react-pdf__Page__canvas {
    margin: 0 auto;
    /* width: 700 !important; */
  }


  