@media only screen and (orientation: portrait) {
    .landscapeContent {
        display: none !important;
    }
}

@media only screen and (orientation: landscape) {
    .portraitContent {
        display: none !important;
    }
}
.portraitContent {
    justifyContent: center;
    alignItems: center;
}
